import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import {
  CURRENCY,
  rentalContractWorkScheduleEnumOptions,
} from '~/shared/config/constants'
import { RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import { priceFormat } from '~/shared/lib/utils'
import { Select } from '~/shared/ui/form'
import { FormValues } from './model'
import { Price } from './styled'

const defaultValues = {
  workSchedule: {
    id: RentalContractWorkScheduleEnum.FULL_WEEK,
    label: RentalContractWorkScheduleEnum.FULL_WEEK,
  },
}

export type FormProps = {
  isSubmitting?: boolean
  onSuccess?: (formValues: FormValues) => void
  pricePerDay: number
}

export function Form({ isSubmitting, onSuccess, pricePerDay }: FormProps) {
  const form = useForm<FormValues>({
    defaultValues,
  })

  const workSchedule = form.watch('workSchedule')

  const price = useMemo(() => {
    if (workSchedule?.id === RentalContractWorkScheduleEnum.SIX_THROUGH_ONE) {
      return (pricePerDay * 7) / 6
    }
    return pricePerDay
  }, [pricePerDay, workSchedule?.id])

  return (
    <>
      <FormContainer formContext={form} onSuccess={onSuccess}>
        <Select
          options={rentalContractWorkScheduleEnumOptions}
          name='workSchedule'
          disabled={isSubmitting}
        />

        <Price>
          {priceFormat(price)} {CURRENCY} / сутки
        </Price>
      </FormContainer>
    </>
  )
}
