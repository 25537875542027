import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { Car, CarAttributes } from '~/shared/api'
import {
  FetchFn,
  SCROLLABLE_LIST_META_PAGE,
  ScrollableList,
  Item,
  Sort,
} from '~/shared/ui/ScrollableList'
import { SkeletonList } from '~/shared/ui/SkeletonList'
import { sortConfig } from './config'
import { ActionButtonsWrapper } from './styled'
import { Card } from './ui/Card'
import { Filters } from './ui/Filters'
import { Skeleton } from './ui/Skeleton'
import { useExternalFilters } from './useExternalFilters'

type Row = Item & CarAttributes

export function ListCars() {
  const { externalFilters, regionId } = useExternalFilters()

  const fetchRows = useCallback<FetchFn<Row>>(
    async (page, pageSize, filters, sort) => {
      let builder = Car.limit(pageSize)

      sort && (builder = builder.orderBy(sort))

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()

      const items = data.map((car) => {
        return {
          id: car?.getApiId() as string,
          ...car.getAttributes(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const { to, currentPage, lastPage } =
        axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

      return {
        items,
        to,
        currentPage,
        lastPage,
      }
    },
    [],
  )

  const handleMapItems = useCallback(
    (items: Row[]) => (
      <>
        {items.map(({ id, ...attr }) => (
          <Box mb='24px' key={id}>
            <Card carId={id} carAttributes={attr} />
          </Box>
        ))}
      </>
    ),
    [],
  )

  return (
    <>
      <ActionButtonsWrapper>
        <Sort options={sortConfig} />
        <Filters />
      </ActionButtonsWrapper>

      {!regionId ? (
        <SkeletonList
          count={10}
          skeletonNode={<Skeleton />}
          itemMarginBottom='24px'
        />
      ) : (
        <ScrollableList
          queryKey='list-cars'
          externalFilters={externalFilters}
          fetchFn={fetchRows}
          mapItems={handleMapItems}
          skeletonNode={<Skeleton />}
          skeletonItemMarginBottom='24px'
          empty={
            <Typography fontSize='18' mt='40px'>
              По выбранному региону автомобилей не найдено!
            </Typography>
          }
        />
      )}
    </>
  )
}
