import { styled, Chip as MuiChip } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled('div')`
  width: 100%;
  aspect-ratio: 1/0.7;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Img = styled('img')`
  width: auto;
  height: auto;
  object-fit: contain;
`

export const Chip = styled(MuiChip)`
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.white.main};
  border: none;
  position: absolute;
  left: 12px;
  top: 12px;
`

export const IsLeasingFullyPaidChip = styled(MuiChip)`
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.blue['500']};
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
`
