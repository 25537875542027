import { IonDatetime, IonModal } from '@ionic/react'
import { Button } from '@mui/material'
import { useRef } from 'react'
import { useModal } from '~/shared/ui/Modal'
import {
  TitleModal,
  ModalWrapper,
  ButtonModalWrapper,
  IonInput,
} from './styled'

type DatetimeProps = {
  name: string
  title: string
  label: string
  min?: string
  max?: string
  onChange: (value?: string) => void
  value?: string
}

export function Datetime({
  name,
  title,
  label,
  min,
  max,
  onChange,
  value,
}: DatetimeProps) {
  const { isModalShow, closeModal, openModal } = useModal()
  const datetimeRef = useRef<null | HTMLIonDatetimeElement>(null)

  const formatYear = (date?: string | null) => {
    if (!date) return undefined

    return new Date(date).getFullYear().toString()
  }

  const handleChange = () => {
    datetimeRef?.current?.confirm()
    onChange(formatYear(datetimeRef?.current?.value as string))
    closeModal()
  }

  const handleClear = () => {
    closeModal()
    datetimeRef.current?.reset()
    onChange(undefined)
  }

  const handleClose = () => {
    closeModal()
  }

  return (
    <div style={{ width: '100%' }}>
      <div onClick={openModal}>
        <IonInput
          value={value}
          label={label}
          name={name}
          readonly
          mode='md'
          labelPlacement='floating'
          fill='outline'
        />
      </div>

      <IonModal
        isOpen={isModalShow}
        onDidDismiss={closeModal}
        style={{
          '--width': 'fit-content',
          '--height': 'fit-content',
          '--border-radius': '20px',
        }}
      >
        <ModalWrapper>
          <TitleModal>
            {title} {label}
          </TitleModal>
          <IonDatetime
            ref={datetimeRef}
            value={value}
            presentation='year'
            preferWheel
            min={min}
            max={max}
          />
          <ButtonModalWrapper>
            <Button fullWidth variant='aliceBlueFlooded' onClick={handleClose}>
              Отменить
            </Button>
            <Button fullWidth variant='blueFlooded' onClick={handleClear}>
              Очистить
            </Button>
            <Button fullWidth variant='limeFlooded' onClick={handleChange}>
              Применить
            </Button>
          </ButtonModalWrapper>
        </ModalWrapper>
      </IonModal>
    </div>
  )
}
