import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'

export const useMainTaxiparks = () => {
  const mainTaxiparks = useStoreMap(driverModel.$driver, (driver) =>
    (driver?.getTaxiparks() || []).find(
      (taxipark) => taxipark.getApiId() === driver?.getMainTaxiparkId(),
    ),
  )

  return mainTaxiparks?.getAttributes()
}
