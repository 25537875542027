import { Button, Typography } from '@mui/material'
import { carVehicleCategoryModel } from 'src/widgets/CarVehicleCategoryDrawer'
import { useMainTaxiparks } from '~/widgets/MainTaxipark'
import { Wrapper } from './styled'

export function EmptyCar() {
  const hasOnlineRental = useMainTaxiparks()?.hasOnlineRental

  return (
    <Wrapper>
      <Typography
        data-testid='empty-car'
        fontSize='22px'
        color='white'
        mb='24px'
        fontWeight={500}
      >
        У вас еще нет <br /> арендованного авто
      </Typography>

      {hasOnlineRental && (
        <Button
          variant='limeFlooded'
          size='large'
          fullWidth
          onClick={() => carVehicleCategoryModel.openCarVehicleCategoryDrawer()}
        >
          Арендовать авто
        </Button>
      )}
    </Wrapper>
  )
}
