import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled('a')`
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
  background-color: ${myPalette.white.main};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  text-decoration: none;
  padding: 16px;
  color: ${myPalette.black.main};
  cursor: pointer;
`
export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const Descriptions = styled('div')`
  font-size: 12px;
`

export const Phone = styled(Title)`
  font-weight: 400;
`
