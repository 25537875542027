import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { filterElement, filtersConfig } from '~/features/Cars/ListCars/config'
import { Region } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { IonCheckbox as Checkbox } from '~/shared/ui/form/Checkbox'
import { DrawerWrapper } from '~/shared/ui/form/Select/styled'
import { ChevronDownIcon, GeopointIcon } from '~/shared/ui/icons'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { useFiltersQueryParams } from '~/shared/ui/ScrollableList'
import { myPalette } from '~/shared/ui/theme'
import { Title, Button } from './styled'

const { brandModel, price } = filterElement

export function RegionsFilter() {
  const [isShow, openDrawer, closeDrawer] = useDrawer()

  const [filtersQuery, setFiltersQuery] = useFiltersQueryParams(filtersConfig)

  const regionIdQuery = filtersQuery.regionId?.id
  const regionLabelQuery = filtersQuery.regionId?.label

  const handleOnChange = (option: Option) => {
    closeDrawer()
    setFiltersQuery(
      {
        regionId: option,
        [price.option.fromField]: undefined,
        [price.option.toField]: undefined,
        [brandModel.first.field]: undefined,
        [brandModel.second.field]: undefined,
      },
      'replaceIn',
    )
  }

  const { isFetching, data } = useQuery<Option[]>(
    ['main-regions-options'],
    () => Region.fetchOptions(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (resOptions) => {
        const firstRegion = resOptions?.[0]
        if (!regionIdQuery && firstRegion) {
          setFiltersQuery(
            {
              regionId: firstRegion,
            },
            'replaceIn',
          )
        }
      },
    },
  )

  const options = useMemo(() => data ?? [], [data])

  return (
    <div>
      <Button
        startIcon={<GeopointIcon />}
        endIcon={
          isFetching ? (
            <ProgressBlock height={35} size={14} />
          ) : (
            <div style={{ transform: 'rotate(-90deg)' }}>
              <ChevronDownIcon color={myPalette.white.main} />
            </div>
          )
        }
        disabled={isFetching}
        variant='text'
        onClick={openDrawer}
      >
        {regionLabelQuery || 'Выберите регион'}
      </Button>
      <DrawerComponent isShow={isShow} onCancel={closeDrawer}>
        <DrawerWrapper>
          <Title>Выбор региона</Title>

          {!options.length && <div>Ошибка получения регионов</div>}

          {options.map((option) => (
            <Checkbox
              onClick={() => handleOnChange(option)}
              key={option.id}
              labelPlacement='end'
              justify='start'
              checked={option?.id === regionIdQuery}
            >
              {option.label}
            </Checkbox>
          ))}
        </DrawerWrapper>
      </DrawerComponent>
    </div>
  )
}
