import { useGate, useStoreMap } from 'effector-react'
import { useParams } from 'react-router-dom'
import {
  LoadingBlock,
  RentalCar,
  rentalCarModel,
} from 'src/features/Cars/RentalCar'
import { carModel } from 'src/entities/Car'

import { ProgressBlock } from '~/shared/ui/Loaders'
import { NotFound } from '~/shared/ui/NotFound'
import { PageContainer } from '~/shared/ui/PageContainer'

export const RentalCarPage = () => {
  const params = useParams<{ carId: string }>()
  const paramsCarId = params.carId as string

  useGate(rentalCarModel.Gate, { id: paramsCarId })

  const carId = useStoreMap(rentalCarModel.$car, (car) => car?.getApiId())
  const carTitle = useStoreMap(
    rentalCarModel.$car,
    (car) => `${car?.getBrand()} ${car?.getModel()}`,
  )
  const carLoaded = carId === paramsCarId
  const title = `${carLoaded ? carTitle : ''}`

  const displayName = carLoaded ? (
    carTitle
  ) : (
    <ProgressBlock height={20} size={16} />
  )

  const carError = carModel.useCarError(paramsCarId)
  if (carError) {
    return <NotFound message='Не удалось загрузить автомобиль' />
  }

  return (
    <PageContainer title={title} backTitle={displayName} canBack>
      {carLoaded ? <RentalCar /> : <LoadingBlock />}
    </PageContainer>
  )
}
