import { styled, Button as MuiButton } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const Button = styled(MuiButton)`
  padding: 3px;
  font-size: 14px;
  color: ${myPalette.white.main};

  &[disabled] {
    color: ${myPalette.white.main};
  }
`
