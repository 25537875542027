import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const MuiButton: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'aliceBlueFlooded' },
        style: {
          backgroundColor: `${myPalette.aliceBlue.main} !important`,
          borderRadius: '8px',
          padding: '8px 24px',
          color: myPalette.midnightBlue.main,
          fontSize: '16px',
          fontWeight: 500,
        },
      },
      {
        props: { variant: 'limeFlooded' },
        style: {
          backgroundColor: `${myPalette.limeGreen.main} !important`,
          borderRadius: '8px',
          padding: '8px 24px',
          color: myPalette.midnightBlue.main,
          fontSize: '16px',
          fontWeight: 500,

          '&.MuiButton-sizeLarge': {
            padding: '14px 24px',
          },
        },
      },
      {
        props: { variant: 'whiteFlooded' },
        style: {
          backgroundColor: `${myPalette.white.main} !important`,
          boxShadow: `0 0 5px ${myPalette.dark.shadowLight}`,
          borderRadius: '8px',
          padding: '8px 24px',
          color: myPalette.midnightBlue.main,
          fontSize: '16px',
          fontWeight: 500,

          '&.MuiButton-sizeLarge': {
            padding: '15px 24px',
          },
        },
      },
      {
        props: { variant: 'blueFlooded' },
        style: {
          backgroundColor: `${myPalette.blue.main} !important`,
          borderRadius: '8px',
          padding: '8px 24px',
          color: myPalette.white.main,
          fontSize: '16px',
          fontWeight: 500,

          '&.MuiButton-sizeLarge': {
            padding: '15px 24px',
          },
        },
      },
      {
        props: { variant: 'lightSteelBlueBorder' },
        style: {
          backgroundColor: `${myPalette.white.main} !important`,
          borderRadius: '8px',
          padding: '6px 24px',
          color: myPalette.midnightBlue.main,
          border: `1px solid ${myPalette.blue.lightSteelBlue}`,
          fontSize: '16px',
          fontWeight: 500,

          '&.MuiButton-sizeLarge': {
            padding: '13px 24px',
          },
        },
      },
    ],
  },
}
