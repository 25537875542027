import { ComponentProps } from 'react'
import { IonCheckbox } from './styled'

type CheckboxProps = {
  label: string
  onChange: (value: boolean) => void
  checked: boolean
} & Omit<ComponentProps<typeof IonCheckbox>, 'onChange'>

export function Checkbox({
  label,
  onChange,
  checked,
  ...checkboxProps
}: CheckboxProps) {
  return (
    <IonCheckbox
      checked={checked}
      onIonChange={(e) => {
        onChange(e.detail.checked)
      }}
      {...checkboxProps}
      labelPlacement='start'
      justify='space-between'
      mode='md'
    >
      {label}
    </IonCheckbox>
  )
}
