import { Skeleton } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { driverModel } from '~/entities/Driver'
import { myPalette } from '~/shared/ui/theme'
import { Title, SubTitle, Wrapper, Button } from './styled'
import { useMainTaxiparks } from './useMainTaxiparks'

type MainTaxiparkProps = {
  variant?: 'white' | 'blue'
  canChange?: boolean
}

export function MainTaxipark({ variant, canChange }: MainTaxiparkProps) {
  const driverPending = useStore(driverModel.getDriverFx.pending)

  const taxiparksCount = useStoreMap(
    driverModel.$driver,
    (driver) => driver?.getTaxiparks()?.length || 0,
  )

  const mainTaxiparkName = useMainTaxiparks()?.name

  const isTaxiparkChange = mainTaxiparkName && taxiparksCount > 1

  const taxiparkTitle = useMemo(() => {
    if (driverPending) {
      return <Skeleton variant='rounded' height={24} width='100%' />
    }

    return mainTaxiparkName
  }, [driverPending, mainTaxiparkName])

  const styleVariant = useMemo(() => {
    if (variant === 'blue') {
      return {
        wrapper: { background: myPalette.midnightBlue['100'] },
        title: { color: myPalette.white.main },
      }
    }
  }, [variant])

  return (
    <Wrapper style={{ ...styleVariant?.wrapper }}>
      <SubTitle>Активный таксопарк</SubTitle>
      <Title style={{ ...styleVariant?.title }} data-testid='active-taxipark'>
        {taxiparkTitle}
      </Title>
      {canChange && isTaxiparkChange && (
        <Button component={Link} to='/taxiparks' variant='lightSteelBlueBorder'>
          Изменить
        </Button>
      )}
    </Wrapper>
  )
}
