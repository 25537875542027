import { useQuery } from '@tanstack/react-query'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDebounce } from 'use-debounce'
import { Option } from '~/shared/config/constants'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { SkeletonList } from '~/shared/ui/SkeletonList'
import { filterOptions } from './helpers'
import { RadioGroup } from './RadioGroup'
import {
  Title,
  DriverWrapper,
  InputWrapper,
  InputIcon,
  ReadOnlyInput,
  SearchInput,
  SearchTitle,
} from './styled'

type AsyncSelectProps<T> = {
  field: string
  fetchOptions: (search: string) => Promise<T[]>
  label: string
  title?: string
  modalTitle: string
  queryKey?: unknown[]
  canAsyncSearch?: boolean
  onChange?: () => void
}

export function AsyncSelect<T extends Option>({
  field,
  fetchOptions,
  label,
  title,
  modalTitle,
  queryKey,
  canAsyncSearch,
  onChange,
}: AsyncSelectProps<T>) {
  const [isShow, openDrawer, closeDrawer] = useDrawer()
  const { setValue, watch } = useFormContext()

  const [search, setSearch] = useState<string>('')

  const valueForm: Option | undefined = watch(field)

  const handleDrawerClose = () => {
    setSearch('')
    closeDrawer()
  }

  const handleOnChange = (option: Option) => {
    setValue(field, option)
    handleDrawerClose()
    onChange?.()
  }

  const [debouncedSearch] = useDebounce(canAsyncSearch ? search : '', 200)

  const { isFetching, data } = useQuery<T[]>(
    ['async-select-options', field, debouncedSearch, ...(queryKey || [])],
    () => fetchOptions(debouncedSearch),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const options = useMemo(() => data ?? [], [data])

  const handleInputChange = useCallback(
    (value?: string | null) => {
      setSearch(value || '')
    },
    [setSearch],
  )

  const filteredOptions = useMemo(
    () => (canAsyncSearch ? options : filterOptions(search, options)),
    [canAsyncSearch, options, search],
  )

  return (
    <div>
      {title && <Title>{title}</Title>}

      <InputWrapper onClick={openDrawer}>
        <ReadOnlyInput
          value={valueForm?.label}
          label={label}
          mode='md'
          readonly
          labelPlacement='floating'
          fill='outline'
        />
        <InputIcon icon={isShow ? chevronUpOutline : chevronDownOutline} />
      </InputWrapper>

      <DrawerComponent
        isShow={isShow}
        onCancel={handleDrawerClose}
        contentProps={{
          className: 'ion-content-scroll-host',
          sx: { height: '90vh' },
        }}
      >
        <DriverWrapper>
          <Title>{modalTitle}</Title>

          <SearchInput
            label='Поиск'
            mode='md'
            labelPlacement='floating'
            fill='outline'
            onIonInput={(e) => handleInputChange(e.detail.value)}
          />

          {isFetching && <SkeletonList count={10} skeletonHeight={56} />}

          {!isFetching && !options.length && <div>Ошибка получения данных</div>}

          {!isFetching && !filteredOptions.length && (
            <div>Ничего не найдено</div>
          )}

          {!isFetching && Boolean(filteredOptions?.length) && (
            <>
              <SearchTitle>Результат</SearchTitle>

              <RadioGroup
                options={filteredOptions}
                value={valueForm}
                onChange={handleOnChange}
              />
            </>
          )}
        </DriverWrapper>
      </DrawerComponent>
    </div>
  )
}
