import { Skeleton as MuiSkeleton } from '@mui/material'

export function Skeleton() {
  return (
    <div style={{ width: '100%' }}>
      <MuiSkeleton
        variant='rectangular'
        width='100%'
        sx={{
          borderRadius: '16px',
          aspectRatio: '1/0.7',
          marginBottom: '12px',
          height: 'auto',
        }}
      />
      <MuiSkeleton
        variant='rectangular'
        height={107}
        width='100%'
        sx={{ borderRadius: '16px' }}
      />
    </div>
  )
}
