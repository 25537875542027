import { useQuery } from '@tanstack/react-query'
import { Car } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const useDependentOptions = (
  regionId: UniqueId,
  selectedBrands?: Option[],
) => {
  const { isFetching, data = {} } = useQuery(
    ['brand-model', regionId],
    () => Car.getModels(regionId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const brandOptions = Object.entries(data).map(([brand]) => ({
    id: brand,
    label: brand,
  }))

  const filteredModels = selectedBrands?.length
    ? selectedBrands.reduce<[string, string[]][]>((acc, { id }) => {
        if (data[id]) {
          acc.push([id, data[id]])
        }
        return acc
      }, [])
    : Object.entries(data)

  const modelOptions = filteredModels.reduce(
    (acc: Option[], [brand, models]) => {
      return [
        ...acc,
        ...(models || []).map((model) => ({
          id: model,
          label: model,
          gropedByKey: brand,
        })),
      ]
    },
    [],
  )

  return { isFetchingDependentOptions: isFetching, brandOptions, modelOptions }
}
