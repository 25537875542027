import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const DrawerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 46px;
`

export const Line = styled('div')`
  background: ${myPalette.blue.lightGrayishBlue};
  height: 1px;
  width: 100%;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  gap: 8px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16px;
  z-index: 2;
`

export const ApplyButtonTitle = styled('div')`
  display: flex;
  align-items: center;
`
