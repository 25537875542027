import { useStore } from 'effector-react'
import { pushFx } from '~/shared/lib/history'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { filterHelpers } from '~/shared/ui/ScrollableList'
import { Content, FormValues } from './Content'
import {
  $isCarVehicleCategoryDrawerOpen,
  closeCarVehicleCategoryDrawer,
} from './model'

export function CarVehicleCategoryDrawer() {
  const isOpen = useStore($isCarVehicleCategoryDrawerOpen)

  const handleGoToCatalog = ({ vehicleCategories }: FormValues) => {
    const query = vehicleCategories?.length
      ? `?vehicleCategory=${encodeURIComponent(
          filterHelpers.encodeOptions(vehicleCategories) || '',
        )}`
      : ''
    pushFx(`/cars${query}`)
    closeCarVehicleCategoryDrawer()
  }

  return (
    <DrawerComponent isShow={isOpen} onCancel={closeCarVehicleCategoryDrawer}>
      <Content onSuccess={handleGoToCatalog} />
    </DrawerComponent>
  )
}
