import { PaletteColorOptions } from '@mui/material'
import { ruRU as coreRuRU } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { myPalette } from './myPalette'
import {
  typography,
  MuiAccordion,
  MuiButton,
  MuiForm,
  MuiList,
  MuiTypography,
} from './overrides'

declare module '@mui/material/styles' {
  interface Theme {
    myPalette: typeof myPalette
  }

  interface PaletteOptions {
    limeGreen: PaletteColorOptions
    aliceBlue: PaletteColorOptions
    midnightBlue: PaletteColorOptions
    greyMutedIcon: PaletteColorOptions
  }
}

declare module '@mui/material/colors' {
  interface PaletteOptions {
    limeGreen?: PaletteColorOptions
    aliceBlue?: PaletteColorOptions
    midnightBlue?: PaletteColorOptions
    greyMutedIcon?: PaletteColorOptions
  }
}

declare module '@mui/material' {
  // Colors
  interface SvgIconPropsColorOverrides {
    limeGreen: true
    aliceBlue: true
    greyMutedIcon: true
    white: true
    midnightBlue: true
    coral: true
  }
  interface ButtonPropsColorOverrides {
    white: true
  }
  interface CheckboxPropsColorOverrides {
    limeGreen: true
  }
  interface BadgePropsColorOverrides {
    coral: true
  }
  interface CircularProgressPropsColorOverrides {
    limeGreen: true
    aliceBlue: true
    midnightBlue: true
  }
  interface IconButtonPropsColorOverrides {
    aliceBlue: true
    blue: true
  }

  // Variants
  interface ButtonPropsVariantOverrides {
    aliceBlueFlooded: true
    whiteFlooded: true
    limeFlooded: true
    lightSteelBlueBorder: true
    blueFlooded: true
  }

  interface RadioPropsColorOverrides {
    midnightBlue: true
  }
}

export const BODY_MAX_WIDTH = '540px'
export const HEADER_HEIGHT = '80px'
export const MAIN_LAYOUT_MENU_ID = 'MAIN_LAYOUT_MENU'

export const theme = createTheme(
  {
    ...typography,
    components: {
      ...MuiAccordion,
      ...MuiButton,
      ...MuiForm,
      ...MuiTypography,
      ...MuiList,
    },
    palette: {
      ...myPalette,
    },
  },
  coreRuRU,
)
