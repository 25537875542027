export function FiltersIcon() {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.25012 8.25021C0.835909 8.25021 0.500122 8.586 0.500122 9.00021C0.500122 9.41443 0.835908 9.75021 1.25012 9.75021L4.04646 9.75021L4.04646 10.2137C4.04646 10.6279 4.38225 10.9637 4.79646 10.9637L7.14996 10.9637C7.56417 10.9637 7.89996 10.6279 7.89996 10.2137L7.89996 9.75021L19.2501 9.75021C19.6643 9.75021 20.0001 9.41443 20.0001 9.00021C20.0001 8.586 19.6643 8.25021 19.2501 8.25021L7.89996 8.25021L7.89996 7.8602C7.89996 7.44599 7.56418 7.1102 7.14996 7.1102L4.79646 7.1102C4.38225 7.1102 4.04646 7.44599 4.04646 7.8602L4.04646 8.25021L1.25012 8.25021ZM1.00012 1.25021C0.585909 1.25021 0.250122 1.586 0.250122 2.00021C0.250122 2.41443 0.585909 2.75021 1.00012 2.75021L12.4503 2.75021L12.4503 3.42485C12.4503 3.701 12.6741 3.92485 12.9503 3.92485L15.7999 3.92485C16.0761 3.92485 16.2999 3.701 16.2999 3.42485L16.2999 2.75021L19.0001 2.75021C19.4143 2.75021 19.7501 2.41443 19.7501 2.00021C19.7501 1.586 19.4143 1.25021 19.0001 1.25021L16.2999 1.25021L16.2999 0.575195C16.2999 0.299053 16.0761 0.0751951 15.7999 0.0751951L12.9503 0.075195C12.6741 0.075195 12.4503 0.299053 12.4503 0.575195L12.4503 1.25021L1.00012 1.25021ZM0.500121 16.0002C0.500121 15.586 0.835908 15.2502 1.25012 15.2502L10.3466 15.2502L10.3466 14.5402C10.3466 14.2641 10.5704 14.0402 10.8466 14.0402L13.7001 14.0402C13.9762 14.0402 14.2001 14.2641 14.2001 14.5402L14.2001 15.2502L19.2501 15.2502C19.6643 15.2502 20.0001 15.586 20.0001 16.0002C20.0001 16.4144 19.6643 16.7502 19.2501 16.7502L14.2001 16.7502L14.2001 17.3937C14.2001 17.6698 13.9762 17.8937 13.7001 17.8937L10.8466 17.8937C10.5704 17.8937 10.3466 17.6698 10.3466 17.3937L10.3466 16.7502L1.25012 16.7502C0.835908 16.7502 0.500121 16.4144 0.500121 16.0002Z'
        fill='#292A43'
      />
    </svg>
  )
}
