import { useFormContext } from 'react-hook-form'
import { Datetime } from './Datetime'
import { DateWrapper, Title } from './styled'

type YearRangeProps = {
  title: string
  option: {
    fromField: string
    toField: string
    fromLabel: string
    toLabel: string
  }
}

export function YearRange({
  title,
  option: { fromField, toField, fromLabel, toLabel },
}: YearRangeProps) {
  const { setValue, watch } = useFormContext()

  const fromValue = watch(fromField)
  const toValue = watch(toField)

  return (
    <div>
      <Title>{title}</Title>

      <DateWrapper>
        <Datetime
          name={fromField}
          title={title}
          label={fromLabel}
          max={toValue}
          onChange={(value) => setValue(fromField, value)}
          value={fromValue}
        />
        <Datetime
          name={toField}
          title={title}
          label={toLabel}
          min={fromValue}
          onChange={(value) => setValue(toField, value)}
          value={toValue}
        />
      </DateWrapper>
    </div>
  )
}
