import { useStore } from 'effector-react'
import { Wrapper } from '~/features/Cars/RentalCar/styled'
import {
  Form,
  Title,
  Characteristics,
  RentalButton,
  Photos,
} from 'src/entities/Car'

import { $car } from './model'

export function RentalCar() {
  const car = useStore($car)

  if (!car) return null

  const getPlateNumber = car.getPlateNumber()
  const pricePerDay = car.getPricePerDay()
  const photos = car.getPhotos()
  const isLeasingFullyPaid = car.getIsLeasingFullyPaid()

  return (
    <Wrapper>
      <Photos
        plateNumber={getPlateNumber}
        photos={photos}
        isLeasingFullyPaid={isLeasingFullyPaid}
      />

      <div>
        <Title>Стоимость аренды</Title>
        <Form pricePerDay={pricePerDay} />
      </div>

      <Characteristics car={car} />

      <RentalButton />
    </Wrapper>
  )
}
