import * as z from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { FuelTypeEnum, TransmissionEnum } from '../config/enums'
import { ApiModel } from './core'

const attributesSchema = z.object({
  manufactureYear: z.string(),
  color: z.string(),
  fuelType: z.nativeEnum(FuelTypeEnum),
  brand: z.string(),
  model: z.string(),
  plateNumber: z.string(),
  pricePerDay: z.number(),
  transmission: z.nativeEnum(TransmissionEnum),
  vehicleCategory: z.string(),
  photos: z.array(z.string()),
  isLeasingFullyPaid: z.boolean().optional(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarAttributes = z.infer<typeof attributesSchema>

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getModel(): string {
    return this.getAttribute('model')
  }
  getBrand(): string {
    return this.getAttribute('brand')
  }
  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }
  getPricePerDay(): number {
    return this.getAttribute('pricePerDay')
  }
  getPhotos(): string[] {
    return this.getAttribute('photos')
  }
  getIsLeasingFullyPaid(): boolean {
    return this.getAttribute('isLeasingFullyPaid')
  }

  static async getPrices(
    regionId: UniqueId,
  ): Promise<{ min: string | null; max: string | null }> {
    const url = `${Car.getJsonApiUrl()}/actions/prices?regionId=${regionId}`
    const client = Car.httpClient.getImplementingClient()
    const { data } = await client.get<{
      data: {
        minPrice: string | null
        maxPrice: string | null
      }
    }>(url)

    return {
      min: data?.data?.minPrice || null,
      max: data?.data?.maxPrice || null,
    }
  }

  static async getModels(regionId: UniqueId) {
    const url = `${Car.getJsonApiUrl()}/actions/models?regionId=${regionId}`
    const client = Car.httpClient.getImplementingClient()
    const { data } = await client.get<{ data: Record<string, string[]> }>(url)

    return data?.data
  }
}
