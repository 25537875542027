export function MenuCarIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <path
        d='M11.75 22H8.5C8.22386 22 8 21.7761 8 21.5V17.5C8 17.2239 8.22386 17 8.5 17H27.5C27.7761 17 28 17.2239 28 17.5V21.5C28 21.7761 27.7761 22 27.5 22H26.25M15 22H22.375'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <path
        d='M12.8743 12.3143C12.9502 12.1245 13.1341 12 13.3385 12H22.6615C22.8659 12 23.0498 12.1245 23.1257 12.3143L24.7257 16.3143C24.8571 16.6427 24.6152 17 24.2615 17H11L12.8743 12.3143Z'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <circle cx='13' cy='22' r='2' stroke='#E4ECFF' strokeWidth='1.2' />
      <circle cx='24' cy='22' r='2' stroke='#E4ECFF' strokeWidth='1.2' />
    </svg>
  )
}
