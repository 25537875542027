import {
  QueryParamConfigMap,
  StringParam,
  NumberParam,
  ObjectParam,
  BooleanParam,
  useQueryParams,
  JsonParam,
  DelimitedArrayParam,
  DecodedValueMap,
} from 'use-query-params'

import {
  decodeOption,
  decodeOptions,
  encodeOption,
  encodeOptions,
} from './helpers'
import { Filter, FilterType } from './types'

export const paramToFilterType: Record<
  FilterType,
  | typeof StringParam
  | typeof ObjectParam
  | typeof BooleanParam
  | typeof JsonParam
  | typeof DelimitedArrayParam
> = {
  autocomplete: {
    ...StringParam,
    encode: encodeOption,
    decode: decodeOption,
  },
  autocompleteMultipleDependent: {
    ...StringParam,
    encode: encodeOptions,
    decode: decodeOptions,
  },
  range: NumberParam,
  yearRange: StringParam,
  multiButtons: {
    ...StringParam,
    encode: encodeOptions,
    decode: decodeOptions,
  },
}

export function useFiltersQueryParams(filters: Filter[]) {
  const queryParamConfigMap: QueryParamConfigMap = Object.fromEntries(
    filters.flatMap((filter) => {
      if (filter.type === 'autocompleteMultipleDependent') {
        return [
          [filter.first.field, paramToFilterType[filter.type]],
          [filter.second.field, paramToFilterType[filter.type]],
        ]
      }
      if (filter.type === 'range' || filter.type === 'yearRange') {
        return [
          [filter.option.fromField, paramToFilterType[filter.type]],
          [filter.option.toField, paramToFilterType[filter.type]],
        ]
      }
      return [[filter.field, paramToFilterType[filter.type]]]
    }),
  )
  return useQueryParams(queryParamConfigMap)
}

export const mapFiltersQueryParamsToList = (
  filtersQuery: DecodedValueMap<QueryParamConfigMap>,
) => {
  return Object.entries(filtersQuery)
    .filter(([, filter]) => {
      if (Array.isArray(filter) && filter?.length) return true
      if (typeof filter === 'object') return Boolean(filter?.id)
      return Boolean(filter)
    })
    .map(([attr, filter]) => {
      let value: string

      if (Array.isArray(filter) && filter?.length) {
        value = filter.map(({ id }) => id).join(',')
      } else if (typeof filter === 'object') {
        value = filter.id
      } else {
        value = String(filter)
      }
      return { key: attr, value }
    })
}

export const sanitizeFilterFields = (filtersConfig: Filter[]) => {
  return filtersConfig
    .flatMap((filter) => {
      if (filter?.excludeClear) return []

      if (filter.type === 'autocompleteMultipleDependent') {
        return [[filter.first.field], [filter.second.field]]
      } else if (filter.type === 'range' || filter.type === 'yearRange') {
        return [[filter.option.fromField], [filter.option.toField]]
      } else if (filter.field) {
        return [[filter.field]]
      }
      return []
    })
    .flatMap((filter) => filter)
}
