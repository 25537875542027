import { Skeleton } from '~/shared/ui/SkeletonList'

export function LoadingBlock() {
  return (
    <>
      <Skeleton
        animated
        style={{ marginBottom: '32px', aspectRatio: '1/0.7' }}
      />
      <Skeleton animated $height={27} style={{ marginBottom: '16px' }} />
      <Skeleton animated $height={56} style={{ marginBottom: '16px' }} />
      <Skeleton animated $height={33} style={{ marginBottom: '32px' }} />
      <Skeleton animated $height={27} style={{ marginBottom: '16px' }} />
      <Skeleton animated $height={24} style={{ marginBottom: '12px' }} />
      <Skeleton animated $height={24} style={{ marginBottom: '12px' }} />
      <Skeleton animated $height={24} style={{ marginBottom: '12px' }} />
      <Skeleton animated $height={24} style={{ marginBottom: '12px' }} />
      <Skeleton animated $height={24} style={{ marginBottom: '32px' }} />
      <Skeleton animated $height={86} style={{ marginBottom: '32px' }} />
    </>
  )
}
