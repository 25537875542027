import {
  IonRadioGroup as IonicIonRadioGroup,
  IonRadio as IonicIonRadio,
} from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IonRadioGroup = styled(IonicIonRadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const IonRadio = styled(IonicIonRadio)`
  height: 30px;
  --border-radius: 50px;
  --inner-border-radius: 50px;

  --color: ${myPalette.limeGreen.main};
  --color-checked: ${myPalette.limeGreen.main};

  &.radio-checked::part(container) {
    border-color: ${myPalette.limeGreen.main};
  }

  &::part(container) {
    border-color: ${myPalette.blue.lightSteelBlue};
    border-width: 1px;
    width: 22px;
    height: 22px;
  }

  &::part(label) {
    margin-left: 10px;
  }
`
