import { createDomain, sample, combine } from 'effector'
import { createGate } from 'effector-react'
import { carModel } from 'src/entities/Car'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.cars.car')
export const Gate = createGate<{ id: UniqueId }>()

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)

export const $car = combine($id, carModel.$carsCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

sample({
  clock: $id,
  filter: isString,
  target: carModel.requestFx,
})
