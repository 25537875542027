import { Button as MuiButton, ButtonGroup, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const WrapperButtonGroup = styled(ButtonGroup)`
  border-radius: 16px;
  border-color: transparent;

  .MuiButtonGroup-grouped {
    border-radius: 16px;
    border-color: transparent !important;
  }

  .MuiButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .MuiButtonGroup-grouped:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const Button = styled(MuiButton)<{ select: boolean }>(({ select }) => ({
  ...(select ? { backgroundColor: `${myPalette.blue.light} !important` } : {}),
  boxShadow: 'none',
}))
