import { IonCheckbox as IonicIonCheckbox } from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IonCheckbox = styled(IonicIonCheckbox)`
  --size: 22px;
  --border-color: ${myPalette.blue.lightSteelBlue};
  --checkbox-background-checked: ${myPalette.limeGreen.main};
  --border-color-checked: ${myPalette.limeGreen.main};
  --checkmark-width: 2px;

  &::part(container) {
    border-radius: 4px;
    border-width: 1px;
  }

  &.checkbox-checked::part(container) {
    border-radius: 4px;
    border-width: 1px;
    border-color: ${myPalette.limeGreen.main};
  }
`
