import { Option } from '~/shared/config/constants'
import { isString } from '~/shared/lib/utils'

export const encodeOption = (option?: Option | null | undefined) => {
  if (!option) return
  return `id-${option.id}_label-${option.label}`
}

export const decodeOption = (
  value: string | (string | null)[] | null | undefined,
) => {
  if (!isString(value)) return

  const match = value.match(/id-([^_]+)_label-(.+)/)

  if (match) {
    return { id: match[1], label: match[2] }
  }
  return
}

export const encodeOptions = (options?: Option[]) => {
  if (!options?.length) return
  return options
    .map((option) => `id-${option.id}_label-${option.label}`)
    .join('|')
}

export const decodeOptions = (
  value: string | (string | null)[] | null | undefined,
) => {
  if (!isString(value)) return

  const options = value.split('|')

  return options.map((value) => decodeOption(value)).filter(Boolean)
}
