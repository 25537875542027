import { IonInput as IonicIonInput, IonIcon } from '@ionic/react'
import { styled, Button as MuiButton } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const DriverWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled('div')`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const SearchTitle = styled('div')`
  font-size: 16px;
  line-height: 15px;
  font-weight: 500;
`

export const Button = styled(MuiButton)`
  padding: 3px;
  font-size: 14px;
  color: ${myPalette.white.main};

  &[disabled] {
    color: ${myPalette.white.main};
  }
`

export const InputWrapper = styled('div')`
  position: relative;
`

export const InputIcon = styled(IonIcon)`
  position: absolute;
  top: 16px;
  right: 8px;
  z-index: 2;
`

export const ReadOnlyInput = styled(IonicIonInput)`
  && {
    pointer-events: none;
    --background: ${myPalette.white.main};
    --border-color: ${myPalette.blue.lightSteelBlue} !important;
    --border-width: 1px !important;
    --padding-start: 16px;
    --border-radius: 6px;
    min-height: 48px;

    &.has-value {
      .label-text-wrapper {
        transform: translateY(-32%) scale(0.75) !important;
      }
    }

    .label-text-wrapper {
      color: ${myPalette.blue.lightSteelBlue} !important;
      transform: translateY(80%) !important;
    }

    input {
      margin-right: 16px;
    }
  }
`

export const SearchInput = styled(IonicIonInput)`
  && {
    --background: ${myPalette.white.main};
    --border-color: ${myPalette.blue.lightSteelBlue};
    --border-width: 1px !important;
    --padding-start: 16px;
    --border-radius: 6px;
    min-height: 48px;

    &.has-value,
    &.has-focus {
      --border-color: ${myPalette.blue.main};

      .label-text-wrapper {
        transform: translateY(-32%) scale(0.75) !important;
      }
    }

    .label-text-wrapper {
      color: ${myPalette.blue.lightSteelBlue} !important;
      transform: translateY(80%) !important;
    }
  }
`
