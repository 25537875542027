export function GeopointIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 9.96898C20 13.8178 16.4183 20.9202 12 20.9202C7.58172 20.9202 4 13.8178 4 9.96898C4 6.12012 7.58172 3 12 3C16.4183 3 20 6.12012 20 9.96898Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <ellipse
        cx='11.9985'
        cy='10.6811'
        rx='2.66667'
        ry='2.56003'
        fill='white'
      />
    </svg>
  )
}
