import { IonApp, setupIonicReact, useIonLoading } from '@ionic/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import adapterLocale from 'date-fns/locale/ru'
import { useGate } from 'effector-react'
import { parse, stringify } from 'query-string'
import { HelmetProvider } from 'react-helmet-async'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { Routing } from '~/pages'
import { isDevEnv, isMobileApp } from '~/shared/config/env'
import { AppUrlListener } from '~/shared/lib/AppUrlListener'
import { history } from '~/shared/lib/history'
import { loadingModel } from '~/shared/lib/loading'
import { NotificationModal } from '~/shared/lib/notificationModal'
import { NotificationsProvider } from '~/shared/lib/notifications'
import { sentryModel } from '~/shared/lib/sentry'
import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'
import { GlobalStyles } from '~/shared/ui/global-styles'
import { ReloadApp } from '~/shared/ui/ReloadApp'
import { ReloadSw } from '~/shared/ui/ReloadSw'
import { BODY_MAX_WIDTH, theme } from '~/shared/ui/theme'
import './App.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

const queryClient = new QueryClient()

setupIonicReact({
  mode: 'ios',
})

export function App() {
  const loadingParams = useIonLoading()

  useGate(sentryModel.SentryGate)
  useGate(loadingModel.LoadingGate, loadingParams)

  return (
    <ErrorBoundary>
      <IonApp
        style={{
          maxWidth: BODY_MAX_WIDTH,
          margin: '0 auto',
        }}
      >
        <ThemeProvider theme={theme}>
          {!isMobileApp && <ReloadSw />}
          {isMobileApp && !isDevEnv && <ReloadApp />}

          <CssBaseline />
          <GlobalStyles />
          <NotificationModal />
          <NotificationsProvider>
            <HistoryRouter history={history}>
              <AppUrlListener />
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <QueryClientProvider client={queryClient}>
                  <LocalizationProvider
                    adapterLocale={adapterLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <HelmetProvider>
                      <Routing />
                    </HelmetProvider>
                  </LocalizationProvider>
                </QueryClientProvider>
              </QueryParamProvider>
            </HistoryRouter>
          </NotificationsProvider>
        </ThemeProvider>
      </IonApp>
    </ErrorBoundary>
  )
}
