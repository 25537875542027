import { CarAttributes } from '~/shared/api'
import { CURRENCY } from '~/shared/config/constants'
import { Characteristics } from './Characteristics'
import {
  Title,
  Wrapper,
  Price,
  DescriptionWrapper,
  ImageWrapper,
  Img,
  Chip,
} from './styled'

type CardProps = {
  carId: UniqueId
  carAttributes: CarAttributes
}

export function Card({ carId, carAttributes }: CardProps) {
  const photo = carAttributes.photos?.[0]
  return (
    <Wrapper to={carId}>
      <ImageWrapper>
        {carAttributes?.isLeasingFullyPaid && (
          <Chip label='Возможен выкуп' variant='outlined' size='small' />
        )}
        <Img src={photo} alt='car-photo' />
      </ImageWrapper>

      <DescriptionWrapper>
        <Title>
          {carAttributes?.brand} {carAttributes?.model}
        </Title>

        <Characteristics carAttributes={carAttributes} />

        <Price>
          {carAttributes.pricePerDay?.toLocaleString()} {CURRENCY} / сутки
        </Price>
      </DescriptionWrapper>
    </Wrapper>
  )
}
