import { IonInfiniteScroll } from '@ionic/react'
import { List, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import {
  NumberParam,
  useQueryParam,
  withDefault,
  StringParam,
} from 'use-query-params'
import { ProgressBlock } from '../Loaders'
import { SkeletonList } from '../SkeletonList'
import {
  SCROLLABLE_LIST_DEFAULT_VALUES,
  SCROLLABLE_LIST_PAGE_KEY,
  SCROLLABLE_LIST_SORT_KEY,
} from './constants'
import { FetchFn, Item, ListData } from './types'

type ScrollableListProps<Row extends Item> = {
  queryKey: string
  pageSizeParams?: number
  skeletonHeight?: number
  skeletonNode?: ReactNode
  skeletonItemMarginBottom?: string
  fetchFn: FetchFn<Row>
  externalFilters?: { key: string; value: string }[]
  empty?: ReactNode
  mapItems: (items: Row[]) => ReactNode
}

export function ScrollableList<Row extends Item>({
  pageSizeParams = 10,
  skeletonHeight,
  skeletonNode,
  skeletonItemMarginBottom,
  fetchFn,
  externalFilters,
  empty,
  mapItems,
  queryKey,
}: ScrollableListProps<Row>) {
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement | null>(null)
  const isMounted = useRef<boolean>(true)
  const [isOnInfinite, toggleIsOnInfinite] = useState(false)
  const [isShowSkeleton, toggleIsShowSkeleton] = useState(false)

  const [pageQuery, setPageQuery] = useQueryParam(
    SCROLLABLE_LIST_PAGE_KEY,
    withDefault(NumberParam, 1),
  )

  const [sortQuery] = useQueryParam(
    SCROLLABLE_LIST_SORT_KEY,
    withDefault(StringParam, ''),
  )

  const memoGridKey = useMemo(
    () => [queryKey, sortQuery, pageQuery, externalFilters],
    [queryKey, externalFilters, pageQuery, sortQuery],
  )

  const [debounceGridKey] = useDebounce(memoGridKey, 300)
  const [previewData, setPreviewData] = useState<ListData<Row>>()

  const pageSize = useMemo(
    () =>
      isMounted.current && pageQuery
        ? pageQuery * pageSizeParams
        : pageSizeParams,
    [pageQuery, pageSizeParams],
  )

  const { isLoading, data } = useQuery(
    debounceGridKey,
    async () => {
      const page = isMounted.current && pageQuery ? undefined : pageQuery

      const { items, currentPage, lastPage, to } = await fetchFn(
        page,
        pageSize,
        externalFilters?.length ? externalFilters : [],
        sortQuery,
      )

      return {
        items: uniqBy([...(previewData?.items || []), ...(items || [])], 'id'),
        currentPage,
        lastPage,
        to,
      }
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (updateData) => {
        setPreviewData(updateData)
        isMounted.current = false
        infiniteScrollRef.current?.complete()
        toggleIsOnInfinite(false)
        toggleIsShowSkeleton(false)
      },
      onError: () => {
        isMounted.current = false
        infiniteScrollRef.current?.complete()
        toggleIsOnInfinite(false)
        toggleIsShowSkeleton(false)
      },
    },
  )

  const {
    items = [],
    to,
    currentPage,
    lastPage,
  } = useMemo(() => data || SCROLLABLE_LIST_DEFAULT_VALUES, [data])

  const handlePageChange = (pageParams?: number) => {
    setPageQuery(pageParams ?? pageQuery + 1, 'replaceIn')
  }

  const filtrationByExternalFilter = () => {
    if (isMounted.current) return
    setPreviewData(undefined)
    handlePageChange(1)
    toggleIsShowSkeleton(true)
  }

  const watchFilterKeys = useMemo(
    () => (externalFilters || []).map(({ value }) => value).join(','),
    [externalFilters],
  )
  useEffect(() => {
    filtrationByExternalFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFilterKeys, sortQuery])

  if (isLoading || isShowSkeleton)
    return (
      <SkeletonList
        count={pageSize}
        skeletonHeight={skeletonHeight}
        skeletonNode={skeletonNode}
        itemMarginBottom={skeletonItemMarginBottom}
      />
    )

  if (!to) {
    return (
      <>{empty || <Typography fontSize='18'>Нечего не найдено</Typography>}</>
    )
  }

  return (
    <>
      <List>{mapItems(items)}</List>

      <IonInfiniteScroll
        threshold='200px'
        ref={infiniteScrollRef}
        onIonInfinite={() => {
          if (isOnInfinite) return
          handlePageChange()
          toggleIsOnInfinite(true)
        }}
        disabled={currentPage >= lastPage}
      >
        <ProgressBlock height={32} size={28} />
      </IonInfiniteScroll>
    </>
  )
}
