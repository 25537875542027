import { useStore } from 'effector-react'
import { useDrawer } from '~/shared/ui/Drawer'
import { $isSupportDrawerOpen, closeSupportDrawer } from './model'
import {
  CarNotStartDrawer,
  DriverSupportServiceDrawer,
  BreakdownOnRoad,
  ParkAddressAndContactsDrawer,
  RoadAccidentDrawer,
  TabsDrawer,
  DocumentsDrawer,
  TrafficPoliceFinesDrawer,
} from './ui'

export function Support() {
  const isSupportOpen = useStore($isSupportDrawerOpen)

  const [
    isDriverSupportServiceShow,
    driverSupportServiceOpen,
    driverSupportServiceClose,
  ] = useDrawer()
  const [
    isParkAddressAndContactsShow,
    parkAddressAndContactsOpen,
    parkAddressAndContactsClose,
  ] = useDrawer()
  const [isCarNotStartShow, carNotStartOpen, carNotStartClose] = useDrawer()
  const [isRoadAccidentShow, roadAccidentOpen, roadAccidentClose] = useDrawer()
  const [isBreakdownOnRoadShow, breakdownOnRoadOpen, breakdownOnRoadClose] =
    useDrawer()
  const [isDocumentsShow, documentsOpen, documentsClose] = useDrawer()
  const [
    isTrafficPoliceFinesShow,
    trafficPoliceFinesOpen,
    trafficPoliceFinesClose,
  ] = useDrawer()

  return (
    <>
      <TabsDrawer
        isShow={isSupportOpen}
        onClose={closeSupportDrawer}
        onDriverSupportService={driverSupportServiceOpen}
        onParkAddressAndContactsOpen={parkAddressAndContactsOpen}
        onCarNotStartOpen={carNotStartOpen}
        onRoadAccidentOpen={roadAccidentOpen}
        onBreakdownOnRoadOpen={breakdownOnRoadOpen}
        onDocumentsOpen={documentsOpen}
        onTrafficPoliceFinesDrawerOpen={trafficPoliceFinesOpen}
      />

      <DriverSupportServiceDrawer
        isShow={isDriverSupportServiceShow}
        onClose={driverSupportServiceClose}
      />
      <ParkAddressAndContactsDrawer
        isShow={isParkAddressAndContactsShow}
        onClose={parkAddressAndContactsClose}
      />
      <CarNotStartDrawer
        isShow={isCarNotStartShow}
        onClose={carNotStartClose}
      />
      <RoadAccidentDrawer
        isShow={isRoadAccidentShow}
        onClose={roadAccidentClose}
      />
      <BreakdownOnRoad
        isShow={isBreakdownOnRoadShow}
        onClose={breakdownOnRoadClose}
      />
      <DocumentsDrawer isShow={isDocumentsShow} onClose={documentsClose} />
      <TrafficPoliceFinesDrawer
        isShow={isTrafficPoliceFinesShow}
        onClose={trafficPoliceFinesClose}
      />
    </>
  )
}
