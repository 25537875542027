import { Option } from '~/shared/config/constants'

export const filterOptions = <T extends Option>(
  search: string | undefined,
  options: T[],
) => {
  if (!search) return options

  const normalizedQuery = search.toLowerCase()
  return options.filter((option) =>
    option.label.toLowerCase().includes(normalizedQuery),
  )
}

export const groupOptionsByKey = <T extends Option>(
  groupKey: string,
  options: T[],
) => {
  return Object.entries(
    options.reduce((data: Record<string, T[]>, item) => {
      // TODO: доработать тип получение item по groupKey
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const groupItem = item[groupKey]

      if (!data[groupItem]) {
        data[groupItem] = []
      }

      data[groupItem].push(item)
      return data
    }, {}),
  )
}
