import { Button } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { carVehicleCategoriesOptions, Option } from '~/shared/config/constants'
import { MultiButtons } from '~/shared/ui/ScrollableList'

export type FormValues = { vehicleCategories: Option[] }

type ContentProps = {
  onSuccess: (values: FormValues) => void
}

export function Content({ onSuccess }: ContentProps) {
  const form = useForm<FormValues>()

  return (
    <FormContainer formContext={form} onSuccess={onSuccess}>
      <MultiButtons
        field='vehicleCategories'
        options={carVehicleCategoriesOptions}
        title='Выбор тарифа авто'
      />

      <Button
        sx={{ marginTop: '24px' }}
        variant='limeFlooded'
        size='large'
        fullWidth
        type='submit'
      >
        Применить
      </Button>
    </FormContainer>
  )
}
