import { styled } from '@mui/material'

export const DateWrapper = styled('div')`
  display: flex;
  gap: 8px;
`

export const Title = styled('div')`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`
