import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Row = styled('div')`
  display: flex;
  align-items: end;
  justify-content: space-between;
`

export const Label = styled('div')``

export const Value = styled(Label)`
  color: ${myPalette.mutedText.main};
`

export const Dots = styled('div')`
  flex-grow: 1;
  border-bottom: 1px dotted ${myPalette.mutedText.main};
  margin: 0 8px 7px;
`
