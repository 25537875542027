import { Routes, Route } from 'react-router-dom'
import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/auth/protected-page'
import NotFoundPage from './404'
import { ListBalancePage } from './Balance'
import { ListBankCardsPage } from './Balance/ListBankCardsPage'
import { ListBalanceHistoriesPage } from './BalanceHistories'
import { ListCarsPage } from './Cars/ListCarsPage'
import { RentalCarPage } from './Cars/RentalCarPage'
import HomePage from './Home'
import LoginPage from './login'
import { ListTaxiparksPage, ProtectedDriverPage } from './Taxiparks'

export const Routing: React.FC = () => {
  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<ProtectedDriverPage />}>
          <Route element={<MainLayout />}>
            <Route path='/' element={<HomePage />} id={'home'} />

            <Route path='/balance' element={<ListBalancePage />} />
            <Route path='/bank-cards' element={<ListBankCardsPage />} />

            <Route
              path='/balance-histories'
              element={<ListBalanceHistoriesPage />}
            />

            <Route path='/cars' element={<ListCarsPage />} />
            <Route path='/cars/:carId' element={<RentalCarPage />} />
          </Route>
        </Route>

        <Route path='/taxiparks' element={<ListTaxiparksPage />} />
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
