import { MainTaxipark } from '~/widgets/MainTaxipark'
import { ListCars, RegionsFilter } from '~/features/Cars/ListCars'
import { PageContainer } from '~/shared/ui/PageContainer'

export function ListCarsPage() {
  return (
    <PageContainer
      title='Аренда авто'
      backTitle='Аренда авто'
      canBack
      preventScrollReset
      topContent={
        <div>
          <div style={{ marginBottom: '24px' }}>
            <RegionsFilter />
          </div>
          <MainTaxipark variant='blue' />
        </div>
      }
    >
      <ListCars />
    </PageContainer>
  )
}
