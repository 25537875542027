import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { Button } from '@mui/material'
import { useQueryParam, withDefault, StringParam } from 'use-query-params'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { IonCheckbox as Checkbox } from '~/shared/ui/form/Checkbox'
import { DrawerWrapper } from '~/shared/ui/form/Select/styled'
import { SCROLLABLE_LIST_SORT_KEY } from '~/shared/ui/ScrollableList'
import { myPalette } from '~/shared/ui/theme'
import { Title } from './styled'

type Option = { key: string; label: string }

type SortProps = {
  options: Option[]
}

export function Sort({ options }: SortProps) {
  const [isShow, openDrawer, closeDrawer] = useDrawer()

  const [sortQuery, setSortQuery] = useQueryParam(
    SCROLLABLE_LIST_SORT_KEY,
    withDefault(StringParam, ''),
  )

  const handleOnChange = (option: Option) => {
    closeDrawer()
    setSortQuery(option.key, 'replaceIn')
  }

  return (
    <div>
      <Button
        fullWidth
        startIcon={
          <SyncAltIcon
            sx={{
              transform: 'rotate(90deg)',
              fontSize: '24px !important',
            }}
          />
        }
        variant='text'
        onClick={openDrawer}
        sx={{
          padding: 0,
          color: myPalette.black.main,
        }}
      >
        Сортировка
      </Button>
      <DrawerComponent isShow={isShow} onCancel={closeDrawer}>
        <DrawerWrapper>
          <Title>Сортировка</Title>

          {options.map((option) => (
            <Checkbox
              onClick={() => handleOnChange(option)}
              key={option.key}
              labelPlacement='end'
              justify='start'
              checked={option?.key === sortQuery}
            >
              {option.label}
            </Checkbox>
          ))}
        </DrawerWrapper>
      </DrawerComponent>
    </div>
  )
}
