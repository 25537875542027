import { styled, Chip as MuiChip } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const ChipWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
  overflow: auto;
`

export const Chip = styled(MuiChip)`
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.blue['500']};
  border: none;
`
