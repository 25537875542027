import { IonCheckbox as IonicIonCheckbox } from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IonCheckbox = styled(IonicIonCheckbox)`
  --size: 22px;
  --border-color: ${myPalette.blue.lightSteelBlue};
  --border-color-checked: ${myPalette.limeGreen.main};
  --checkbox-background-checked: ${myPalette.white.main};

  position: relative;

  &[checked='true']::after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${myPalette.limeGreen.main};
    display: block;
    position: absolute;
    top: 8px;
    left: 7px;
    border-radius: 10px;
  }
`
