import { IonInput as IonicIonInput } from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const ModalWrapper = styled('div')`
  padding: 24px;
  width: 300px;
`

export const TitleModal = styled('div')`
  font-size: 18px;
  font-weight: 500;
`

export const ButtonModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const IonInput = styled(IonicIonInput)`
  && {
    pointer-events: none;
    --background: ${myPalette.white.main};
    --border-color: ${myPalette.blue.lightSteelBlue};
    --padding-start: 16px;
    --border-radius: 6px;
    min-height: 48px;

    &.has-value {
      .label-text-wrapper {
        transform: translateY(-32%) scale(0.75) !important;
      }
    }

    .label-text-wrapper {
      color: ${myPalette.blue.lightSteelBlue};
      transform: translateY(80%) !important;
    }
  }
`
