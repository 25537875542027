import { Option } from '~/shared/config/constants'

export const filterOptions = (
  search: string | undefined,
  options: Option[],
) => {
  if (!search) return options

  const normalizedQuery = search.toLowerCase()
  return options.filter((option) =>
    option.label.toLowerCase().includes(normalizedQuery),
  )
}
