import { styled } from '@mui/material'

export const Title = styled('div')`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`
