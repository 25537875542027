import {
  IonInput as IonicIonInput,
  IonRange as IonicIonRange,
} from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const InputWrapper = styled('div')`
  display: flex;
  gap: 8px;
`

export const Title = styled('div')`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const IonRange = styled(IonicIonRange)`
  padding: 0;
  margin: 0 4px 14px;
  --height: 26px;

  &::part(knob) {
    background: ${myPalette.blue.main};
  }

  &::part(bar-active) {
    background: ${myPalette.blue.main};
  }

  &::part(bar) {
    background: ${myPalette.blue.lightGrayishBlue};
  }

  &::part(pin) {
    background-color: rgba(16, 41, 202, 0.7);
    color: ${myPalette.white.main};
    border-radius: 12px;
  }
`

export const IonInput = styled(IonicIonInput)`
  && {
    pointer-events: none;
    --background: ${myPalette.white.main};
    --border-color: ${myPalette.blue.lightSteelBlue} !important;
    --border-width: 1px !important;
    --padding-start: 16px;
    --border-radius: 6px;
    min-height: 48px;

    &.has-value {
      .label-text-wrapper {
        transform: translateY(-32%) scale(0.75) !important;
      }
    }

    .label-text-wrapper {
      color: ${myPalette.blue.lightSteelBlue} !important;
      transform: translateY(80%) !important;
    }
  }
`
