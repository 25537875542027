import { Button } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { filtersConfig, filterElement } from '~/features/Cars/ListCars/config'
import { Car, Region } from '~/shared/api'
import {
  DependentSelects,
  AsyncSelect,
  MultiButtons,
  NumberRange,
  useFiltersQueryParams,
  YearRange,
  sanitizeFilterFields,
} from '~/shared/ui/ScrollableList'
import { ApplyButton } from './ApplyButton'
import { DrawerWrapper, Line, Title, ButtonsWrapper } from './styled'
import { useDependentOptions } from './useDependentOptions'

const {
  region,
  brandModel,
  price,
  yearn,
  transmission,
  fuelType,
  vehicleCategory,
} = filterElement

type ContentProps = {
  onClose: () => void
}

export function Content({ onClose }: ContentProps) {
  const [filtersQuery, setFiltersQuery] = useFiltersQueryParams(filtersConfig)

  const form = useForm({
    defaultValues: filtersQuery,
  })

  const { setValue, watch } = form

  const formValues = watch()
  const regionId = formValues[region.field]?.id
  const selectedBrands = formValues[brandModel.first.field]

  const handleOnClear = () => {
    const emptyFields = sanitizeFilterFields(filtersConfig)
    emptyFields.forEach((field) => setValue(field, undefined))
  }

  const handleOnSuccess = (data: Record<string, unknown>) => {
    setFiltersQuery(data, 'replaceIn')
    onClose()
  }

  const handleFieldReset = () => {
    setValue(price.option.fromField, undefined)
    setValue(price.option.toField, undefined)
    setValue(brandModel.first.field, undefined)
    setValue(brandModel.second.field, undefined)
  }

  const { isFetchingDependentOptions, brandOptions, modelOptions } =
    useDependentOptions(regionId, selectedBrands)

  return (
    <FormContainer formContext={form} onSuccess={handleOnSuccess}>
      <DrawerWrapper>
        <Title>Фильтры</Title>

        <AsyncSelect
          {...region}
          fetchOptions={Region.fetchOptions}
          onChange={handleFieldReset}
        />
        <Line />
        <DependentSelects
          {...brandModel}
          first={{ ...brandModel.first, options: brandOptions }}
          second={{ ...brandModel.second, options: modelOptions }}
          isLoading={isFetchingDependentOptions}
        />
        <Line />
        <NumberRange
          {...price}
          fetchOptions={() => Car.getPrices(regionId)}
          queryKey={regionId}
        />
        <Line />
        <YearRange {...yearn} />
        <Line />
        <MultiButtons {...transmission} />
        <Line />
        <MultiButtons {...fuelType} />
        <Line />
        <MultiButtons {...vehicleCategory} />
        <Line />
      </DrawerWrapper>

      <ButtonsWrapper>
        <Button
          fullWidth
          variant='lightSteelBlueBorder'
          onClick={handleOnClear}
        >
          Сбросить
        </Button>

        <ApplyButton filtersQuery={formValues} />
      </ButtonsWrapper>
    </FormContainer>
  )
}
