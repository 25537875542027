import { LoadingButton } from '@mui/lab'
import { CircularProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { DecodedValueMap, QueryParamConfigMap } from 'use-query-params'
import { Car } from '~/shared/api'
import { mapFiltersQueryParamsToList } from '~/shared/ui/ScrollableList'
import { ApplyButtonTitle } from './styled'

type ApplyButtonProps = {
  filtersQuery: DecodedValueMap<QueryParamConfigMap>
}

export function ApplyButton({ filtersQuery }: ApplyButtonProps) {
  const filters = useMemo(
    () => mapFiltersQueryParamsToList(filtersQuery),
    [filtersQuery],
  )

  const getCount = async () => {
    let builder = Car.limit(1)

    filters?.forEach(({ key, value }) => {
      builder = builder.where(key, value)
    })

    const response = await builder.get(1)
    const httpClientResponse = response.getHttpClientResponse()
    const axiosResponse = httpClientResponse?.getUnderlying()
    const { total } = axiosResponse?.data?.meta?.page || { total: 0 }
    return total
  }

  const queryKey = useMemo(() => JSON.stringify(filters), [filters])
  const [debounceGridKey] = useDebounce(queryKey, 300)

  const { isFetching, data } = useQuery(
    ['filters-apply-cars-count', debounceGridKey],
    () => getCount(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  return (
    <LoadingButton type='submit' fullWidth variant='limeFlooded'>
      <ApplyButtonTitle>
        <div style={{ marginRight: '6px' }}>Показать</div>

        <div style={{ minWidth: '16px' }}>
          {isFetching ? (
            <CircularProgress
              color='midnightBlue'
              sx={{
                display: 'block',
                width: '16px !important',
                height: '16px !important',
              }}
            />
          ) : (
            data
          )}
        </div>
      </ApplyButtonTitle>
    </LoadingButton>
  )
}
