import { IonInput as IonicIonInput } from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const DrawerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const IonInput = styled(IonicIonInput)`
  pointer-events: none;

  --border-color: ${myPalette.blue.lightSteelBlue};
  --background: ${myPalette.white.main};

  &&:hover {
    --border-color: ${myPalette.blue.lightSteelBlue};
  }
`
