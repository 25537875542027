import { Chip as MuiChip, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled(Link)`
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
  background-color: ${myPalette.white.main};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  text-decoration: none;
  color: ${myPalette.black.main};
  cursor: pointer;
`

export const ImageWrapper = styled('div')`
  width: 100%;
  aspect-ratio: 1/0.7;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Chip = styled(MuiChip)`
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.blue['500']};
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
`

export const Img = styled('img')`
  width: auto;
  height: auto;
  object-fit: contain;
`

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`

export const DescriptionWrapper = styled('div')`
  padding: 12px 16px 16px;
`

export const Price = styled('div')`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`
