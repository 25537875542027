import { useStore } from 'effector-react'
import { useDrawer } from '~/shared/ui/Drawer'
import {
  $isProfileDrawerOpen,
  $isDriverEmailDrawerOpen,
  closeProfileDrawer,
  openDriverEmailDrawer,
  closeDriverEmailDrawer,
} from './model'

import {
  TabsDrawer,
  PassportDetailsDrawer,
  DriverLicenseDrawer,
  DriverEmailDrawer,
} from './ui'

export function Profile() {
  const isProfileOpen = useStore($isProfileDrawerOpen)
  const isDriverEmailDrawerOpen = useStore($isDriverEmailDrawerOpen)
  const [
    isPassportDetailsDrawerShow,
    passportDetailsDrawerOpen,
    passportDetailsDrawerClose,
  ] = useDrawer()

  const [isDriverLicenseShow, driverLicenseOpen, driverLicenseClose] =
    useDrawer()

  return (
    <>
      <TabsDrawer
        isShow={isProfileOpen}
        onClose={closeProfileDrawer}
        onEmailClick={openDriverEmailDrawer}
        onPassportDetailsClick={passportDetailsDrawerOpen}
        onDriverLicenseClick={driverLicenseOpen}
      />

      <DriverEmailDrawer
        isShow={isDriverEmailDrawerOpen}
        onClose={closeDriverEmailDrawer}
      />

      <PassportDetailsDrawer
        isShow={isPassportDetailsDrawerShow}
        onClose={passportDetailsDrawerClose}
      />

      <DriverLicenseDrawer
        isShow={isDriverLicenseShow}
        onClose={driverLicenseClose}
      />
    </>
  )
}
