import { useStore } from 'effector-react'
import { useState } from 'react'
import { Penalty } from '~/shared/api'
import { useDrawer } from '~/shared/ui/Drawer'
import { $isPenaltiesDrawerOpen, closePenaltiesDrawer } from './model'
import { ListPenaltyDrawer } from './ui/ListPenaltiesDrawer'
import { PenaltyDrawer } from './ui/PenaltyDrawer'

export function Penalties() {
  const isFineOpen = useStore($isPenaltiesDrawerOpen)
  const [selectedPenalty, setSelectedPenalty] = useState<Penalty>()
  const [isPenaltyDrawerShow, penaltyDrawerOpen, penaltyDrawerClose] =
    useDrawer()

  const handleFineDrawerOpen = (fine: Penalty) => {
    penaltyDrawerOpen()
    setSelectedPenalty(fine)
  }

  return (
    <>
      <ListPenaltyDrawer
        isShow={isFineOpen}
        onClose={closePenaltiesDrawer}
        onPenaltyClick={handleFineDrawerOpen}
      />
      <PenaltyDrawer
        isShow={isPenaltyDrawerShow}
        onClose={penaltyDrawerClose}
        penalty={selectedPenalty}
      />
    </>
  )
}
