export function FiltersActiveIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7206_16933)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.25012 11.2502C2.83591 11.2502 2.50012 11.586 2.50012 12.0002C2.50012 12.4144 2.83591 12.7502 3.25012 12.7502L6.04646 12.7502L6.04646 13.2137C6.04646 13.6279 6.38225 13.9637 6.79646 13.9637L9.14996 13.9637C9.56417 13.9637 9.89996 13.6279 9.89996 13.2137L9.89996 12.7502L21.2501 12.7502C21.6643 12.7502 22.0001 12.4144 22.0001 12.0002C22.0001 11.586 21.6643 11.2502 21.2501 11.2502L9.89996 11.2502L9.89996 10.8602C9.89996 10.446 9.56418 10.1102 9.14996 10.1102L6.79646 10.1102C6.38225 10.1102 6.04646 10.446 6.04646 10.8602L6.04646 11.2502L3.25012 11.2502ZM3.00012 4.25021C2.58591 4.25021 2.25012 4.586 2.25012 5.00021C2.25012 5.41443 2.58591 5.75021 3.00012 5.75021L14.4503 5.75021L14.4503 6.42485C14.4503 6.701 14.6741 6.92485 14.9503 6.92485L17.7999 6.92485C18.0761 6.92485 18.2999 6.701 18.2999 6.42485L18.2999 5.75021L21.0001 5.75021C21.4143 5.75021 21.7501 5.41443 21.7501 5.00021C21.7501 4.586 21.4143 4.25021 21.0001 4.25021L18.2999 4.25021L18.2999 3.5752C18.2999 3.29905 18.0761 3.0752 17.7999 3.0752L14.9503 3.0752C14.6741 3.07519 14.4503 3.29905 14.4503 3.5752L14.4503 4.25021L3.00012 4.25021ZM2.50012 19.0002C2.50012 18.586 2.83591 18.2502 3.25012 18.2502L12.3466 18.2502L12.3466 17.5402C12.3466 17.2641 12.5704 17.0402 12.8466 17.0402L15.7001 17.0402C15.9762 17.0402 16.2001 17.2641 16.2001 17.5402L16.2001 18.2502L21.2501 18.2502C21.6643 18.2502 22.0001 18.586 22.0001 19.0002C22.0001 19.4144 21.6643 19.7502 21.2501 19.7502L16.2001 19.7502L16.2001 20.3937C16.2001 20.6698 15.9762 20.8937 15.7001 20.8937L12.8466 20.8937C12.5704 20.8937 12.3466 20.6698 12.3466 20.3937L12.3466 19.7502L3.25012 19.7502C2.83591 19.7502 2.50012 19.4144 2.50012 19.0002Z'
          fill='#292A43'
        />
        <circle
          cx='17.8095'
          cy='4.80952'
          r='4.55952'
          fill='#F46857'
          stroke='#F8FAFF'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_7206_16933'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
