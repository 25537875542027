import { Descriptions, Phone, Title, Wrapper } from './styled'

export function RentalButton() {
  return (
    <Wrapper href='tel:+79344770800'>
      <Title>Аренда авто по телефону:</Title>
      <Phone>+7 (934) 477-08-00</Phone>
      <Descriptions>
        В Москве офис работает по будням 9:00–18:00 и в сб 10:00–16:00; в
        регионах — по будням 9:00–18:00, выходные — сб и вс.
      </Descriptions>
    </Wrapper>
  )
}
