export const SCROLLABLE_LIST_PAGE_KEY = 'page'
export const SCROLLABLE_LIST_SORT_KEY = 'sort'

export const SCROLLABLE_LIST_META_PAGE = {
  to: 0,
  currentPage: 0,
  lastPage: 0,
}

export const SCROLLABLE_LIST_DEFAULT_VALUES = {
  items: [],
  ...SCROLLABLE_LIST_META_PAGE,
}
