import { ComponentProps } from 'react'
import { Option } from '~/shared/config/constants'
import { IonRadioGroup, IonRadio } from './styled'

type RadioGroupProps = {
  options: Option[]
  onChange: (option: Option) => void
  value?: Option | null
} & Omit<ComponentProps<typeof IonRadioGroup>, 'onChange'>

const compareWith = (groupId: Option, itemId: Option) => {
  return groupId?.id === itemId?.id
}

export function RadioGroup({
  options,
  onChange,
  value,
  ...groupProps
}: RadioGroupProps) {
  return (
    <IonRadioGroup
      value={value}
      compareWith={compareWith}
      onIonChange={(e) => {
        onChange(e.detail.value)
      }}
      {...groupProps}
    >
      {options.map((option) => (
        <IonRadio
          mode='md'
          key={option.id}
          value={option}
          labelPlacement='end'
          justify='start'
        >
          {option.label}
        </IonRadio>
      ))}
    </IonRadioGroup>
  )
}
