import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { styled, Skeleton as MuiSkeleton } from '@mui/material'

export const OfferWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LoadingButton = styled(MuiLoadingButton)`
  padding: 4px 8px;
  font-size: 14px;
`

export const Skeleton = styled(MuiSkeleton)`
  height: 36px;
  width: 100%;
  border-radius: 6px;
`
