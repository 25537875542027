import { useMemo } from 'react'
import {
  useFiltersQueryParams,
  mapFiltersQueryParamsToList,
} from '~/shared/ui/ScrollableList'
import { filtersConfig } from './config'

export const useExternalFilters = () => {
  const [filtersQuery] = useFiltersQueryParams(filtersConfig)

  const externalFilters = useMemo(
    () => mapFiltersQueryParamsToList(filtersQuery),
    [filtersQuery],
  )

  return { externalFilters, regionId: filtersQuery.regionId }
}
