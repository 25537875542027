import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { Car } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.car')

export const requestFx = domain.createEffect<UniqueId, Car>({
  handler: fetchCarWithRelations,
})

const {
  $cache: $carsCache,
  useCache: useCarCache,
  updateCache,
} = createCache<Car>({
  domain,
  getEntityId: (car) => car.getApiId() as UniqueId,
})
export { $carsCache, useCarCache }

$carsCache.on(requestFx.doneData, (cache, car) => updateCache(cache, [car]))

export const $carsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useCarError = (id: UniqueId) =>
  useStoreMap($carsError, (errors) => errors[id])

async function fetchCarWithRelations(id: UniqueId) {
  const carRes = await Car.find(id)

  return carRes.getData() as Car
}
