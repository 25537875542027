import { Unstable_Grid2 as Grid } from '@mui/material'
import { useCallback } from 'react'
import { Penalty } from '~/shared/api'
import { CURRENCY } from '~/shared/config/constants'
import { formatDateTimeForUI } from '~/shared/lib/date'
import {
  FetchFn,
  Item,
  SCROLLABLE_LIST_META_PAGE,
  ScrollableList,
} from '~/shared/ui/ScrollableList'
import { EmptyList } from './EmptyList'
import { Card, Date, Sum, TitleBlock, TitleWrapper } from './styled'

type ContentProps = {
  onPenaltyClick: (penalty: Penalty) => void
}

type Row = Item & {
  title: string
  date: string
  sum: string
  penalty: Penalty
}

export function Content({ onPenaltyClick }: ContentProps) {
  const fetchRows = useCallback<FetchFn<Row>>(async (page, pageSize) => {
    const response = await Penalty.limit(pageSize)
      .orderBy('-createdAt')
      .get(page)
    const data = response.getData()

    const items = data.map((penalty) => {
      return {
        id: penalty?.getApiId() as string,
        title: `${penalty.getPenaltyLawArticleNumber()} - ${penalty.getPenaltyLawArticleDescription()}`,
        date: formatDateTimeForUI(penalty.getPenaltyDatetime()),
        sum: penalty.getPaymentAmountDriver(),
        penalty,
      }
    })

    const httpClientResponse = response.getHttpClientResponse()
    const axiosResponse = httpClientResponse?.getUnderlying()
    const { to, currentPage, lastPage } =
      axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

    return {
      items,
      to,
      currentPage,
      lastPage,
    }
  }, [])

  const handleMapItems = useCallback(
    (items: Row[]) => {
      return items.map((item) => (
        <Card key={item.id} onClick={() => onPenaltyClick(item.penalty)}>
          <Grid container>
            <Grid sx={{ p: 0 }} xs={9}>
              <TitleBlock>
                <TitleWrapper noWrap>{item.title}</TitleWrapper>
                <Date>{item.date}</Date>
              </TitleBlock>
            </Grid>
            <Grid sx={{ p: 0 }} xs={3}>
              <Sum noWrap>{`${item.sum} ${CURRENCY}`}</Sum>
            </Grid>
          </Grid>
        </Card>
      ))
    },
    [onPenaltyClick],
  )

  return (
    <ScrollableList
      queryKey='list-penalties-drawer'
      fetchFn={fetchRows}
      empty={<EmptyList />}
      mapItems={handleMapItems}
      skeletonHeight={58}
    />
  )
}
